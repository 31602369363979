import { createSlice } from '@reduxjs/toolkit';
// utils
import { IPartnerState } from '../../@types/partner';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: IPartnerState = {
  isLoading: false,
  error: null,
  partners: [],
  partner: null,
};

const slice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PARTNERS
    getPartnersSuccess(state, action) {
      state.isLoading = false;
      state.partners = action.payload;
    },

    // CREATE PARTNER
    createPartnerSuccess(state, action) {
      state.isLoading = false;
      state.partners = [...state.partners, action.payload];
    },

    // UPDATE PARTNER
    updatePartnerSuccess(state, action) {
      state.isLoading = false;
      state.partners = state.partners.map((partner) =>
        partner.id === action.payload.id ? action.payload : partner
      );
    },

    // DELETE PARTNER
    deletePartnerSuccess(state, action) {
      state.isLoading = false;
      state.partners = state.partners.filter((partner) => partner.id !== action.payload.id);
    },

    // GET PARTNER
    getPartnerSuccess(state, action) {
      state.isLoading = false;
      state.partner = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions

export const {
  startLoading,
  stopLoading,
  hasError,
  getPartnersSuccess,
  createPartnerSuccess,
  updatePartnerSuccess,
  deletePartnerSuccess,
  getPartnerSuccess,
} = slice.actions;

// ----------------------------------------------------------------------
// Compare this snippet from src/redux/slices/product.ts:
// ----------------------------------------------------------------------

export function getPartners() {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/partners');
      dispatch(slice.actions.getPartnersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPartner(id: string) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/partners/${id}`);
      dispatch(slice.actions.getPartnerSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPartner(data: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/partners', data);
      dispatch(slice.actions.createPartnerSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePartner(id: string, data: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/partners/${id}`, data);
      dispatch(slice.actions.updatePartnerSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deletePartner(id: string) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/partners/${id}`);
      dispatch(slice.actions.deletePartnerSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
