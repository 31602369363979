import { createSlice } from '@reduxjs/toolkit';
// utils
import { IPerformanceState } from '../../@types/performance';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: IPerformanceState = {
  isLoading: false,
  error: null,
  performance: null,
  performances: [],
};

const slice = createSlice({
  name: 'performance',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getPerformanceSuccess(state, action) {
      state.isLoading = false;
      state.performance = action.payload;
    },
    getPerformancesSuccess(state, action) {
      state.isLoading = false;
      state.performances = action.payload;
    },
    createPerformanceSuccess(state, action) {
      state.isLoading = false;
      state.performances = [...state.performances, action.payload];
    },
    updatePerformanceSuccess(state, action) {
      state.isLoading = false;
      state.performances = state.performances.map((performance) => {
        if (performance.id === action.payload.id) {
          return action.payload;
        }
        return performance;
      });
    },
    deletePerformanceSuccess(state, action) {
      state.isLoading = false;
      state.performances = state.performances.filter(
        (performance) => performance.id !== action.payload.id
      );
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  getPerformanceSuccess,
  getPerformancesSuccess,
  createPerformanceSuccess,
  updatePerformanceSuccess,
  deletePerformanceSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPerformance(performanceId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/performances/${performanceId}`);
      dispatch(getPerformanceSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getPerformances() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/performances');
      dispatch(getPerformancesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createPerformance(data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.post('/performances', data);
      dispatch(createPerformanceSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function updatePerformance(performanceId: string, data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.patch(`/performances/${performanceId}`, data);
      dispatch(updatePerformanceSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deletePerformance(performanceId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.delete(`/performances/${performanceId}`);
      dispatch(deletePerformanceSuccess({ id: performanceId }));
      dispatch(getPerformances());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
