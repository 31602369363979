import { createSlice } from '@reduxjs/toolkit';
// utils
import { IContactInfoState } from '../../@types/contact-info';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------
const initialState: IContactInfoState = {
  isLoading: false,
  error: null,
  contactInfo: null,
};

const slice = createSlice({
  name: 'contactInfo',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT INFO
    getContactInfoSuccess(state, action) {
      state.isLoading = false;
      state.contactInfo = action.payload;
    },

    // UPDATE CONTACT INFO
    updateContactInfoSuccess(state, action) {
      state.isLoading = false;
      state.contactInfo = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Actions

export const {
  startLoading,
  stopLoading,
  hasError,
  getContactInfoSuccess,
  updateContactInfoSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

// Update Contact Info
export function updateContactInfo(data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.patch('/contact-info/update', data);
      dispatch(updateContactInfoSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// Get Contact Info
export function getContactInfo() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/contact-info');
      dispatch(getContactInfoSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
