import { createSlice } from '@reduxjs/toolkit';
// utils
import { IServiceState } from '../../@types/service';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: IServiceState = {
  isLoading: false,
  error: null,
  services: [],
  service: null,
};

const slice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SERVICES
    getServicesSuccess(state, action) {
      state.isLoading = false;
      state.services = action.payload;
    },

    // UPDATE SERVICE
    updateServiceSuccess(state, action) {
      state.isLoading = false;
      state.service = action.payload;
    },

    // CREATE SERVICE

    createServiceSuccess(state, action) {
      state.isLoading = false;
      state.services = [...state.services, action.payload];
    },

    // DELETE SERVICE
    deleteServiceSuccess(state, action) {
      state.isLoading = false;
      state.services = state.services.filter((service) => service.id !== action.payload);
    },

    // GET SERVICE
    getServiceSuccess(state, action) {
      state.isLoading = false;
      state.service = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  getServicesSuccess,
  getServiceSuccess,
  updateServiceSuccess,
  createServiceSuccess,
  deleteServiceSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getServices() {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/services');
      dispatch(slice.actions.getServicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getService(id: string) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/services/${id}`);
      dispatch(slice.actions.getServiceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createService(data: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/services', data);
      dispatch(slice.actions.createServiceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateService(data: any, id: number) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/services/${id}`, data);
      dispatch(slice.actions.updateServiceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteService(id: string) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/services/${id}`);
      dispatch(slice.actions.deleteServiceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------------------------------------------------

export async function uploadProductImage(file: FormData) {
  try {
    const response = await axios.post(`images`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}
