import { createSlice } from '@reduxjs/toolkit';
// utils
import { ITermsState } from '../../@types/terms-and-conditions';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: ITermsState = {
  isLoading: false,
  error: null,
  term: null,
  terms: [],
};

const slice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TERMS
    getTermsSuccess(state, action) {
      state.isLoading = false;
      state.terms = action.payload;
    },

    // CREATE TERM
    createTermSuccess(state, action) {
      state.isLoading = false;
      state.terms = [...state.terms, action.payload];
    },

    // UPDATE TERM
    updateTermSuccess(state, action) {
      state.isLoading = false;
      state.terms = state.terms.map((term) =>
        term.id === action.payload.id ? action.payload : term
      );
    },

    // DELETE TERM
    deleteTermSuccess(state, action) {
      state.isLoading = false;
      state.terms = state.terms.filter((term) => term.id !== action.payload.id);
    },
  },
});

// Reducer
export default slice.reducer;

// --------------------------------------------------------------

export const {
  startLoading,
  stopLoading,
  hasError,
  getTermsSuccess,
  createTermSuccess,
  updateTermSuccess,
  deleteTermSuccess,
} = slice.actions;

export const getTerms = () => async (dispatch: any) => {
  dispatch(startLoading());
  try {
    const response = await axios.get('/terms-and-conditions');
    dispatch(getTermsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error));
  }
};

export const createTerm = (term: any) => async (dispatch: any) => {
  dispatch(startLoading());
  try {
    const response = await axios.post('/terms-and-conditions', term);
    dispatch(createTermSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error));
  }
};

export const updateTerm = (id: any, term: any) => async (dispatch: any) => {
  dispatch(startLoading());
  try {
    const response = await axios.patch(`/terms-and-conditions/${id}`, term);
    dispatch(updateTermSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error));
  }
};

export const deleteTerm = (id: string) => async (dispatch: any) => {
  dispatch(startLoading());
  try {
    const response = await axios.delete(`/terms-and-conditions/${id}`);
    dispatch(deleteTermSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error));
  }
};
