import { createSlice } from '@reduxjs/toolkit';
// utils
import { HDYKUState } from '../../@types/hDYKU';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: HDYKUState = {
  isLoading: false,
  error: null,
  hdyku: [],
  hdykuItem: null,
};

const slice = createSlice({
  name: 'hdyku',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET HDYKU
    getHDYKUSuccess(state, action) {
      state.isLoading = false;
      state.hdyku = action.payload;
    },

    // CREATE HDYKU
    createHDYKUSuccess(state, action) {
      state.isLoading = false;
      state.hdyku.push(action.payload);
    },

    // UPDATE HDYKU
    updateHDYKUSuccess(state, action) {
      state.isLoading = false;
      state.hdyku = state.hdyku.map((hdyku) =>
        hdyku.id === action.payload.id ? action.payload : hdyku
      );
    },

    // DELETE HDYKU
    deleteHDYKUSuccess(state, action) {
      state.isLoading = false;
      state.hdyku = state.hdyku.filter((hdyku) => hdyku.id !== action.payload.id);
    },

    // GET HDYKU
    getHDYKUItemSuccess(state, action) {
      state.isLoading = false;
      state.hdykuItem = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  getHDYKUSuccess,
  createHDYKUSuccess,
  updateHDYKUSuccess,
  deleteHDYKUSuccess,
  getHDYKUItemSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getHDYKU() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/hdyku');
      dispatch(getHDYKUSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getHDYKUItem(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/hdyku/${id}`);
      dispatch(getHDYKUItemSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createHDYKU(data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.post('/hdyku', data);
      dispatch(createHDYKUSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateHDYKU(id: string, data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.patch(`/hdyku/${id}`, data);
      dispatch(updateHDYKUSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteHDYKU(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`/hdyku/${id}`);
      dispatch(deleteHDYKUSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
