import { createSlice } from '@reduxjs/toolkit';
// utils
import { IEventState } from '../../@types/event';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: IEventState = {
  isLoading: false,
  error: null,
  events: [],
  event: null,
  eventData: [],
  statistics: null,
  reservations: [],
  deletedReservations: [],
  canceledReservations: [],
};

const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      state.isLoading = false;
      state.events = [...state.events, action.payload];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      state.isLoading = false;
      state.events = state.events.filter((event) => event.id !== action.payload.id);
      state.events = [...state.events, action.payload];
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      state.isLoading = false;
      state.events = state.events.filter((event) => event.id !== action.payload.id);
    },

    // GET EVENT
    getEventSuccess(state, action) {
      state.isLoading = false;
      state.event = action.payload;
    },

    // GET RESERVATIONS
    getReservationsSuccess(state, action) {
      state.isLoading = false;
      state.reservations = action.payload;
    },

    // GET DELETED RESERVATIONS
    getDeletedReservationsSuccess(state, action) {
      state.isLoading = false;
      state.deletedReservations = action.payload;
    },

    // GET CANCELED RESERVATIONS
    getCanceledReservationsSuccess(state, action) {
      state.isLoading = false;
      state.canceledReservations = action.payload;
    },

    // GET STATISTICS
    getStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.statistics = action.payload;
    },

    // GET EVENT DATA
    getEventDataSuccess(state, action) {
      state.isLoading = false;
      state.eventData = action.payload;
    },
  },
});

// Reducer

export default slice.reducer;

// Actions

export const {
  startLoading,
  stopLoading,
  hasError,
  getEventsSuccess,
  createEventSuccess,
  updateEventSuccess,
  deleteEventSuccess,
  getEventSuccess,
  getReservationsSuccess,
  getDeletedReservationsSuccess,
  getCanceledReservationsSuccess,
  getStatisticsSuccess,
  getEventDataSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getEvents() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/events');
      dispatch(getEventsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createEvent(data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.post('/events', data);
      dispatch(createEventSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function updateEvent(data: any, id: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.patch(`/events/${id}`, data);
      dispatch(updateEventSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function updateTicketContent(data: any, id: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.patch(`/events/update-ticket-content/${id}`, data);
      dispatch(updateEventSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function extendEvent(data: any, id: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.patch(`/events/extend-date/${id}`, data);
      dispatch(updateEventSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function extendDayPeriod(id: number, qty: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/events/extend-day-periods/${id}/${qty}`);
      dispatch(updateEventSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function extendEventDaysPeriods(id: number, qty: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/events/extend-all-days/${id}/${qty}`);
      dispatch(updateEventSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteEvent(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`/events/${id}`);
      dispatch(deleteEventSuccess(response.data));
      dispatch(getEvents());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getEvent(id: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/events/find-one/${id}`);
      dispatch(getEventSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getReservations(periodId: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/reservations/${periodId}`);
      dispatch(getReservationsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function changeDayStatus(dayId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.get(`/reservations/day/change/${dayId}`);
      dispatch(getEvents());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function changePeriodStatus(periodId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.get(`/reservations/period/change/${periodId}`);
      dispatch(getEvents());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function updatePeriodNote(periodId: number, note: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.patch(`/reservations/period/update-note/${periodId}`, { note });
      dispatch(getEvents());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getEventReservations(eventId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/events/reservations/${eventId}`);
      dispatch(getReservationsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getEventDeletedReservations(eventId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/events/deleted-reservations/${eventId}`);
      dispatch(getDeletedReservationsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getEventCanceledReservations(eventId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/events/cancelled-reservations/${eventId}`);
      dispatch(getCanceledReservationsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function sendConfirmed(id: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.get(`/reservations/send-confirmed-reservation/${id}`);
      dispatch(getEvents());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function sendCanceled(id: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.get(`/reservations/cancel/${id}`);
      dispatch(getEvents());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function editReservation(data: any, id: number) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.patch(`/reservations/update/${id}`, data);
      dispatch(getEvents());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// get statistics
export function getStatistics() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/events/find-statistics');
      dispatch(getStatisticsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// get event data
export function getEventData(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/events/find-event-data/${id}`);
      dispatch(getEventDataSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function changePeriodsStatus(eventId: string, data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.patch(`/periods/change-status/${eventId}`, data);
      dispatch(getEventData(eventId));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// update-ticket-validity/:eventId
export function updateTicketValidity(eventId: string, data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.patch(`/periods/update-ticket-validity/${eventId}`, data);
      dispatch(getEventData(eventId));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
// update-tickets/:eventId
export function updateTickets(eventId: string, data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.patch(`/periods/update-tickets/${eventId}`, data);
      dispatch(getEventData(eventId));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createReservation(data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.post('/reservations/panel-create', data);
      dispatch(getEvents());
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      dispatch(stopLoading());
    }
  };
}
