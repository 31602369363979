import { createSlice } from '@reduxjs/toolkit';
// utils
import { IBrandState } from '../../@types/brand';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: IBrandState = {
  isLoading: false,
  error: null,
  brands: [],
  brandItems: [],
  brandPhotos: [],
  brand: null,
};

const slice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BRANDS
    getBrandsSuccess(state, action) {
      state.isLoading = false;
      state.brands = action.payload;
    },

    // CREATE BRAND
    createBrandSuccess(state, action) {
      state.isLoading = false;
      state.brands = [...state.brands, action.payload];
    },

    // UPDATE BRAND
    updateBrandSuccess(state, action) {
      state.isLoading = false;
      state.brands = state.brands.filter((brand) => brand.id !== action.payload.id);
      state.brands = [...state.brands, action.payload];
    },

    // DELETE BRAND
    deleteBrandSuccess(state, action) {
      state.isLoading = false;
      state.brands = state.brands.filter((brand) => brand.id !== action.payload.id);
    },

    // GET BRAND
    getBrandSuccess(state, action) {
      state.isLoading = false;
      state.brand = action.payload;
    },

    // GET BRAND ITEMS
    getBrandItemsSuccess(state, action) {
      state.isLoading = false;
      state.brandItems = action.payload;
    },

    // GET BRAND PHOTOS
    getBrandPhotosSuccess(state, action) {
      state.isLoading = false;
      state.brandPhotos = action.payload;
    },

    // CREATE BRAND ITEM
    createBrandItemSuccess(state, action) {
      state.isLoading = false;
      state.brandItems = [...state.brandItems, action.payload];
    },

    // UPDATE BRAND ITEM
    updateBrandItemSuccess(state, action) {
      state.isLoading = false;
      state.brandItems = state.brandItems.filter((item) => item.id !== action.payload.id);
      state.brandItems = [...state.brandItems, action.payload];
    },

    // DELETE BRAND ITEM
    deleteBrandItemSuccess(state, action) {
      state.isLoading = false;
      state.brandItems = state.brandItems.filter((item) => item.id !== action.payload.id);
    },

    // CREATE BRAND PHOTO
    createBrandPhotoSuccess(state, action) {
      state.isLoading = false;
      state.brandPhotos = [...state.brandPhotos, action.payload];
    },

    // DELETE BRAND PHOTO
    deleteBrandPhotoSuccess(state, action) {
      state.isLoading = false;
      state.brandPhotos = state.brandPhotos.filter((photo) => photo.id !== action.payload.id);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  getBrandsSuccess,
  createBrandSuccess,
  updateBrandSuccess,
  deleteBrandSuccess,
  getBrandSuccess,
  getBrandItemsSuccess,
  createBrandItemSuccess,
  updateBrandItemSuccess,
  deleteBrandItemSuccess,
  getBrandPhotosSuccess,
  createBrandPhotoSuccess,
  deleteBrandPhotoSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getBrands() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/brands');
      dispatch(getBrandsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBrand(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/brands/${id}`);
      dispatch(getBrandSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createBrand(data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.post('/brands', data);
      dispatch(createBrandSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateBrand(data: any, id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.patch(`/brands/${id}`, data);
      dispatch(updateBrandSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteBrand(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`/brands/${id}`);
      dispatch(deleteBrandSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getBrandItems(brandId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/brands/${brandId}/items`);
      dispatch(getBrandItemsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function createBrandItem(data: any, brandId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`/brands/${brandId}/items`, data);
      dispatch(createBrandItemSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateBrandItem(data: any, id: string, brandId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.patch(`/brands/${brandId}/items/${id}`, data);
      dispatch(updateBrandItemSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteBrandItem(id: string, brandId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`/brands/${brandId}/items/${id}`);
      dispatch(deleteBrandItemSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getBrandPhotos(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/brands/${id}/photos`);
      dispatch(getBrandPhotosSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function createBrandPhoto(data: any, id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/brands/${id}/photos/${data?.image_id}`);
      dispatch(createBrandPhotoSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteBrandPhoto(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`/brands/${id}/photos`);
      dispatch(deleteBrandPhotoSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
