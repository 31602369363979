import { createSlice } from '@reduxjs/toolkit';
// types
import { ICreateUser, UsersState } from '../../@types/user';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: UsersState = {
  isInitialized: false,
  error: null,
  users: [],
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isInitialized = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isInitialized = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isInitialized = true;
      state.users = action.payload;
    },

    // CREATE USER
    createUserSuccess(state, action) {
      state.isInitialized = true;
      state.users = [...state.users, action.payload];
    },

    // DELETE USER
    deleteUserSuccess(state, action) {
      state.isInitialized = true;
      state.users = state.users.filter((user) => user.id !== action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, getUsersSuccess, createUserSuccess, deleteUserSuccess } =
  slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`users`);
      dispatch(getUsersSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createUser(user: ICreateUser) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.post(`users`, user);
      dispatch(createUserSuccess(response.data));
    } catch (error) {
      hasError(error);
    }
  };
}

export function deleteUser(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`/users/${id}`);
      dispatch(deleteUserSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
