import { createSlice } from '@reduxjs/toolkit';
// utils
import { NotificationsState } from '../../@types/notification';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: NotificationsState = {
  isLoading: false,
  error: null,
  notifications: [],
  notification: null,
  contactsGroups: [],
  contactsGroup: null,
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    // GET NOTIFICATION
    getNotificationSuccess(state, action) {
      state.isLoading = false;
      state.notification = action.payload;
    },

    // CREATE NOTIFICATION
    createNotificationSuccess(state, action) {
      state.isLoading = false;
      state.notifications = [...state.notifications, action.payload];
    },

    // UPDATE NOTIFICATION
    updateNotificationSuccess(state, action) {
      state.isLoading = false;
      state.notifications = state.notifications.map((notification) =>
        notification.id === action.payload.id ? action.payload : notification
      );
    },

    // DELETE NOTIFICATION
    deleteNotificationSuccess(state, action) {
      state.isLoading = false;
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload.id
      );
    },

    // GET CONTACTS GROUPS
    getContactsGroupsSuccess(state, action) {
      state.isLoading = false;
      state.contactsGroups = action.payload;
    },

    // GET CONTACTS GROUP
    getContactsGroupSuccess(state, action) {
      state.isLoading = false;
      state.contactsGroup = action.payload;
    },

    // CREATE CONTACTS GROUP
    createContactsGroupSuccess(state, action) {
      state.isLoading = false;
      state.contactsGroups = [...state.contactsGroups, action.payload];
    },

    // UPDATE CONTACTS GROUP
    updateContactsGroupSuccess(state, action) {
      state.isLoading = false;
      state.contactsGroups = state.contactsGroups.map((contactsGroup) =>
        contactsGroup.id === action.payload.id ? action.payload : contactsGroup
      );
    },

    // DELETE CONTACTS GROUP
    deleteContactsGroupSuccess(state, action) {
      state.isLoading = false;
      state.contactsGroups = state.contactsGroups.filter(
        (contactsGroup) => contactsGroup.id !== action.payload.id
      );
    },
  },
});

// Reducer

export default slice.reducer;

// Actions

export const {
  startLoading,
  stopLoading,
  hasError,
  getNotificationsSuccess,
  getNotificationSuccess,
  createNotificationSuccess,
  updateNotificationSuccess,
  deleteNotificationSuccess,
  getContactsGroupsSuccess,
  getContactsGroupSuccess,
  createContactsGroupSuccess,
  updateContactsGroupSuccess,
  deleteContactsGroupSuccess,
} = slice.actions;

// ----------------------------------------------------------------------
// GET NOTIFICATIONS
export function getNotifications() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get('notifications');
      dispatch(getNotificationsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// GET NOTIFICATION
export function getNotification(notificationId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`notifications/${notificationId}`);
      dispatch(getNotificationSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// CREATE NOTIFICATION
export function createNotification(notification: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.post('notifications', notification);
      dispatch(createNotificationSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// UPDATE NOTIFICATION
export function updateNotification(id: any, notification: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.put(`notifications/${notification.id}`, notification);
      dispatch(updateNotificationSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// DELETE NOTIFICATION
export function deleteNotification(notificationId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.delete(`notifications/${notificationId}`);
      dispatch(deleteNotificationSuccess({ id: notificationId }));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// GET CONTACTS GROUPS
export function getContactsGroups() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get('notifications/contact-groups');
      dispatch(getContactsGroupsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// GET CONTACTS GROUP
export function getContactsGroup(contactsGroupId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`notifications/contact-groups/${contactsGroupId}`);
      dispatch(getContactsGroupSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// CREATE CONTACTS GROUP
export function createContactsGroup(contactsGroup: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.post('notifications/contact-groups', contactsGroup);
      dispatch(createContactsGroupSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// UPDATE CONTACTS GROUP
export function updateContactsGroup(contactsGroup: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.put(`/contacts-group/${contactsGroup.id}`, contactsGroup);
      dispatch(updateContactsGroupSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// DELETE CONTACTS GROUP
export function deleteContactsGroup(contactsGroupId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.delete(`/contacts-group/${contactsGroupId}`);
      dispatch(deleteContactsGroupSuccess({ id: contactsGroupId }));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// START NOTIFICATION
export function startNotification(notificationId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.get(`/notifications/${notificationId}/start`);
      dispatch(getNotifications());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// STOP NOTIFICATION
export function stopNotification(notificationId: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.get(`/notifications/${notificationId}/stop`);
      dispatch(getNotifications());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
