import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import calendarReducer from './slices/calendar';
import chatReducer from './slices/chat';
import kanbanReducer from './slices/kanban';
import mailReducer from './slices/mail';
import productReducer from './slices/product';
// ----------------------------------------------------------------------
import brandReducer from './slices/brand';
import contactInfoReducer from './slices/contact-info';
import couponReducer from './slices/coupon';
import eventReducer from './slices/event';
import hdykuReducer from './slices/hDYKU';
import messageReducer from './slices/message';
import neighborhoodReducer from './slices/neighborhood';
import partnerReducer from './slices/partner';
import postReducer from './slices/post';
import serviceReducer from './slices/service';
import userReducer from './slices/user';
import notificationReducer from './slices/notification';
import performanceReducer from './slices/performance';
import termsReducer from './slices/terms-and-conditions';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  // ----------------------------------------------------------------------
  brand: brandReducer,
  contactInfo: contactInfoReducer,
  message: messageReducer,
  partner: partnerReducer,
  post: postReducer,
  service: serviceReducer,
  user: userReducer,
  event: eventReducer,
  hdyku: hdykuReducer,
  coupon: couponReducer,
  neighborhood: neighborhoodReducer,
  notification: notificationReducer,
  performance: performanceReducer,
  terms: termsReducer,
});

export default rootReducer;
